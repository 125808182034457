<template>
  <div class="content-wrapper px-3">
    <div class="steps-validation">
      <div
        class="step"
        :class="[step.done && 'done', step.order === current_step && 'current']"
        v-for="(step, index) in steps"
        :key="index"
      >
        <b-icon icon="check-circle" v-if="step.done" class="mr-1" />
        <b-icon icon="circle" v-if="step.current && !step.done" class="mr-1" />
        <small> {{ step.name }}</small>
      </div>
    </div>
    <preparation
      @update="getServiceValidation()"
      :enable_validation_button="enable_validation_button"
      class="mt-4"
      v-if="current_step === 0"
    />
    <validation v-if="current_step === 1" />
    <result class="mt-3" v-if="current_step === 2" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Preparation from "./Preparation/";
import Validation from "./Validation/";
import Result from "./Result/";

export default {
  data() {
    return {
      steps: [],
      current_step: "",
      enable_validation_button: "",
    };
  },
  components: {
    Preparation,
    Validation,
    Result,
  },
  methods: {
    ...mapActions([
      "get_service_validation",
      "hide_application_header",
      "get_service_scope",
    ]),
    sliceAvatar() {
      const length = this.serviceScope?.notifications?.length;
      if (length > 4) {
        return length - 4;
      }
      return 0;
    },
    getServiceValidation() {
      this.get_service_validation({ service_id: this.service_id }).then(
        ({ steps, current_step, enable_validation_button }) => {
          this.steps = steps;
          this.current_step = current_step;
          this.enable_validation_button = enable_validation_button;
        }
      );
    },
  },
  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.hide_application_header(false);
    this.service_id = this.$route.params.service_id;
    this.getServiceValidation();
  },
};
</script>
