<template>
  <div class="text-center">
    <cards-list grid="4" gap="12">
      <router-link
        :to="getUrl(mod)"
        v-for="(mod, index) in modules"
        :key="index"
        class="text-center module card py-3"
        :class="!mod.enabled && 'disabled'"
      >
        <div class="d-flex flex-column justify-content-between h-100">
          <div class="d-md-block d-flex px-3">
            <b-img :src="getIcon(mod.icon)" :alt="mod.name" />
            <section class="my-3 text-md-center text-left px-3">
              <strong class="d-block text-muted mb-2">{{ mod.name }}</strong>
              <small class="d-block text-muted description">
                {{ mod.description }}
              </small>
            </section>
          </div>
          <div class="w-100 px-3">
            <b-progress
              max="100"
              height="9px"
              class="w-100"
              v-if="mod.enabled"
              :variant="mod.progress == 100 ? 'primary' : 'default'"
            >
              <b-progress-bar :value="mod.progress" />
            </b-progress>
          </div>
        </div>
      </router-link>
    </cards-list>
    <div class="mt-md-5 text-center">
      <span class="d-block my-2">
        Por favor preencha todos os módulos antes de iniciar a validação
      </span>
      <b-button
        class="w-100 w-md-auto"
        variant="primary"
        @click="handleStartValidation()"
        :disabled="!enable_validation_button"
      >
        Iniciar validação do serviço
      </b-button>
    </div>
    <b-modal
      hide-footer
      hide-header
      body-class="min-h-40"
      size="md"
      centered
      no-close-on-backdrop
      no-close-on-esc
      id="init-service-validation"
    >
      <h4 class="text-center my-3">Iniciar validação dos módulos</h4>
      <img src="@/assets/images/svg/start-validation.png" alt="" width="100%" />
      <strong class="d-block">
        Atenção você está iniciando a validação deste serviço. Essa ação não
        poderá ser revertida
      </strong>
      <div class="mt-4">
        <b-button
          :disabled="inProgress"
          block
          variant="primary"
          @click="start()"
        >
          Quero continuar
        </b-button>
        <b-button block variant="link" @click="cancel()">Voltar</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import budget from "@/assets/images/budget.svg";
import note from "@/assets/images/note.svg";
import lawyer from "@/assets/images/lawyer.svg";
import technicalSupport from "@/assets/images/technical-support.svg";
import { variantStatus } from "@/helpers/variantStatus";

export default {
  props: {
    enable_validation_button: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      inProgress: false,
      modules: [],
      variantStatus,
      homologation_id: "",
      service_id: null,
      template: null,
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  methods: {
    ...mapActions(["get_modules", "start_service_validation"]),
    handleStartValidation() {
      if (this.enable_validation_button) {
        this.$bvModal.show("init-service-validation");
      }
    },
    cancel() {
      this.$bvModal.hide("init-service-validation");
    },
    start() {
      this.inProgress = true;
      this.start_service_validation({ service_id: this.service_id })
        .then(({ status }) => {
          if (status === 200) {
            this.getModules();
            this.$emit("update");
            this.$bvModal.hide("init-service-validation");
          }

          this.inProgress = false;
        })
        .catch(() => {
          this.inProgress = false;
        });
    },
    getIcon(icon) {
      const icons = {
        budget: budget,
        note: note,
        lawyer: lawyer,
        "technical-support": technicalSupport,
      };
      return icons[icon];
    },
    getUrl(mod) {
      const urls = {
        "Econômico Financeiro": `/homologation/${this.homologation_id}/service/${this.service_id}/${this.user.role}/financial/balance-sheet/`,
        "Técnico Operacional": `/homologation/${this.homologation_id}/service/${this.service_id}/${this.user.role}/operational-technician/location-base/`,
        "Legal Trabalhista": `/homologation/${this.homologation_id}/service/${this.service_id}/${this.user.role}/legal-labor/labor-lawsuits/`,
        Regularidade: `/homologation/${this.homologation_id}/service/${this.service_id}/${this.user.role}/regularity/credit-inquiry/`,
      };
      return urls[mod.name] || "#";
    },
    getModules() {
      this.get_modules(this.service_id).then(({ results }) => {
        this.modules = results;
      });
    },
  },
  mounted() {
    this.homologation_id = this.$route.params.homologation_id;
    this.service_id = this.$route.params.service_id;
    this.getModules();
  },
};
</script>
